<template>
  <div class="zl-list">
    <a-breadcrumb class="breadcrumb">
        <a-breadcrumb-item>首页</a-breadcrumb-item>
        <a-breadcrumb-item>通用设置</a-breadcrumb-item>
        <a-breadcrumb-item>工单系统</a-breadcrumb-item>
        <a-breadcrumb-item>{{smartservice_id?'编辑工单':'提交工单'}}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-box smartservice">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <div class="title flts-title">
          <h3>{{smartservice_id?'编辑工单':'提交工单'}}</h3>
          <div>
            <a-button :loading="confirmLoading" size="small" type="primary" @click="handleOk">保存</a-button>
            <a-button size="small" style="margin-left:10px" @click="handleCancel">返回</a-button>
          </div>
      </div>
      <a-form :form="form" layout="vertical">
        <a-form-item v-for="(item, index) of formData" :key="index" :label="item.label">
          <a-input v-if="item.type === 'input'" placeholder="请输入" v-decorator="[item.name, { rules: item.rules }]"/>
          <a-select v-if="item.type === 'select'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]">
            <!-- <a-select-option v-for="(item, index) of category" :key="index" :value="item.cate_id">{{ item.filter_name }}</a-select-option> -->
            <a-select-option value="system_custom">定制服务</a-select-option>
            <a-select-option value="system_bug_reports">缺陷完善</a-select-option>
          </a-select>

          <a-radio-group  v-if="item.type === 'radio'" v-decorator="[item.name, { rules: item.rules }]">
              <a-radio :value="60">
                高
              </a-radio>
              <a-radio :value="30">
                中
              </a-radio>
              <a-radio :value="10">
                低
              </a-radio>
          </a-radio-group>
          <l-editor v-decorator="[item.name, { rules: item.rules }]" v-if="item.type === 'textarea2'" name="content" :data="editor.content"/>
          <a-upload v-if="item.type === 'upload'" v-decorator="[item.name, { rules: item.rules }]" name="file" 
              :data="uploadParams"
              :action="uploadUrl" 
              :beforeUpload="beforeUpload"
              :fileList="fileList"
              @change="changeFile">
              <a-button>
                  <a-icon type="upload" /> 点击上传
              </a-button>
          </a-upload>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
const formData = [
  {
    name: 'smartservice_category',
    label: '工单类型',
    type: 'select',
    rules: [{ required: true, message: '请输入工单类型!' }]
  },
  {
    name: 'smartservice_level',
    label: '优先级',
    type: 'radio',
    rules: [{ required: true, message: '请输入工单优先级!' }]
  },
  {
    name: 'smartservice_subject',
    label: '工单名称',
    type: 'input',
    rules: [{ required: true, message: '请输入工单名称!' }],
  },
  {
    name: 'smartservice_content',
    label: '工单内容',
    type: 'textarea2',
    rules: [{ required: true, message: '请输入工单内容!' }]
  },
  {
    name: 'smartservice_attachment',
    label: '工单附件',
    type: 'upload',
    rules: [] 
  }
]

import url from '@/utils/URL'
import createFilePath from '@/utils/tools'
export default {
  name: 'EditModal',
  provide() {
      return {
          parent: this
      }
  },
  data() {
    return {
      visible: false,
      loading: false,
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      content: '',
      editor: {},
      uploadUrl: url.uploadQiNiu, 
      uploadParams: {},
      fileList: [],
      smartservice_id:''
    }
  },

  async created() {
    this.visible = true
    this.smartservice_id = this.$route.query.id
    if(this.smartservice_id){
      this.loading = true
    }
    await this.$nextTick()
    if(this.smartservice_id){
      let detailInfo = await this.getDetail()
      this.editor.content = detailInfo.smartservice_content
      if(detailInfo.smartservice_attachment){
        this.fileList[0] = detailInfo.smartservice_attachment
      }
      this.form.setFieldsValue({
        smartservice_category:'system_custom',
        smartservice_subject:detailInfo.smartservice_subject,
        smartservice_level:detailInfo.smartservice_level,
        smartservice_content:detailInfo.smartservice_content,
        smartservice_attachment:detailInfo.smartservice_attachment
      })
      this.loading = false
    }else{
      this.form.setFieldsValue({smartservice_category:'system_custom',smartservice_level:60})
    }
  },
  methods: {
    async getDetail(){
      let res = await this.$store.dispatch('smartserviceDetailAction',{data:{smartservice_id:this.smartservice_id}})
      return res.data
    },
    async handleOk() {
      let params = await this.form.validateFields()
      params.smartservice_content = this.editor.content
      if(this.fileList.length > 0){
        let obj = {
          uid:this.fileList[0].uid,
          name:this.fileList[0].name,
          size:this.fileList[0].size,
          type:this.fileList[0].type,
          path:this.fileList[0].response.key,
          response:this.fileList[0].response,
        }
        params.smartservice_attachment = JSON.stringify(obj)
      }else{
        params.smartservice_attachment = ''
      }
      if(this.smartservice_id){
        params.smartservice_id = this.smartservice_id
      }
      this.confirmLoading = true
      await this.$store.dispatch(this.smartservice_id?'smartserviceUpdateAction':'smartserviceAddAction', {data:params})
      .then(res=>{
        this.confirmLoading = false
        this.$message.success('操作成功~')
        this.$router.go(-1)
      })
      .catch(err=>{
        this.confirmLoading = false
      })
    },
    handleCancel() {
      this.$router.go(-1)
    },
    changeEditor(key, value) {
      this.editor[key] = value
      this.form.setFieldsValue({smartservice_content:value})
    },
    async beforeUpload(file) {
      if (!file) { return false }
      let res = await this.$store.dispatch('massTokenAction', {})
      if (res) {
        this.uploadParams.token = res.data.uptoken
        this.uploadParams.key = createFilePath(file.name)
        this.fileList = []
        return true
      }
      return false
    },
    changeFile(file) {
        this.fileList = file.fileList
    }
  }
}
</script>
<style lang="scss" scoped>
  .smartservice{
    width: 70%;
    margin: 0 auto;
  }
  // .ql-container {
  //   margin: 50px auto;
  //   max-width: 720px;
  // }
  // #smartservice_content {
  //   height: 350px;
  // }
</style>
